import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';

import { HeaderTabs } from '@src/enums';
import { useRouteCreator } from '@src/hooks';
import {
  AuthorizedTemplate,
  BrowseRedirectScreen,
  BrowseScreen,
  ConfirmLawnTreatmentScreen,
  GenericQuoteServiceScreen,
  OrderAerationAndOverseedingScreen,
  OrderAerationScreen,
  OrderBushTrimmingScreen,
  OrderCleanupScreen,
  OrderFlowerBedWeedingScreen,
  OrderGenericServiceScreen,
  OrderGutterCleaningScreen,
  OrderLawnMowingScreen,
  OrderLawnTreatmentScreen,
  OrderLeafRemovalScreen,
  OrderMosquitoPreventionScreen,
  OrderMulchingScreen,
  OrderPoolCleaningScreen,
  ReactivationPoliciesScreen,
} from '@src/pages';
import { OnlyProgrammaticAccess, OrderAccess } from '@src/routers/guards';

import type { User } from '@lawnstarter/ls-react-common/types';
import type { RouteObject } from 'react-router-dom';

export function BrowseRouter({ user }: { user: User }): RouteObject[] {
  const { createRoute } = useRouteCreator();

  return [
    {
      path: WebRoutes.browse,
      element: <AuthorizedTemplate currentTab={HeaderTabs.browse} user={user} />,
      children: [
        createRoute({
          index: true,
          component: <BrowseScreen />,
        }),
        createRoute({
          path: WebRoutes.orderAerationAndOverSeeding,
          component: <OrderAccess component={<OrderAerationAndOverseedingScreen />} />,
        }),
        createRoute({
          path: WebRoutes.orderBushTrimming,
          component: <OrderAccess component={<OrderBushTrimmingScreen />} />,
        }),
        createRoute({
          path: WebRoutes.orderCleanup,
          component: <OrderAccess component={<OrderCleanupScreen />} />,
        }),
        createRoute({
          path: WebRoutes.orderMosquitoPrevention,
          component: <OrderAccess component={<OrderMosquitoPreventionScreen />} />,
        }),
        createRoute({
          path: WebRoutes.orderMulching,
          component: <OrderAccess component={<OrderMulchingScreen />} />,
        }),
        createRoute({
          path: WebRoutes.orderLeafRemoval,
          component: <OrderAccess component={<OrderLeafRemovalScreen />} />,
        }),
        createRoute({
          path: WebRoutes.orderFlowerBedWeeding,
          component: <OrderAccess component={<OrderFlowerBedWeedingScreen />} />,
        }),
        createRoute({
          path: WebRoutes.orderPoolCleaning,
          title: t('services.poolCleaning.appTitles.orderPoolCleaning'),
          component: <OrderAccess component={<OrderPoolCleaningScreen />} />,
        }),
        createRoute({
          path: WebRoutes.orderTreeCare,
          component: (
            <OrderAccess
              component={<OrderGenericServiceScreen serviceType={ServiceType.TreeCare} />}
            />
          ),
        }),
        createRoute({
          path: WebRoutes.orderLandscaping,
          component: (
            <OrderAccess
              component={<OrderGenericServiceScreen serviceType={ServiceType.Landscaping} />}
            />
          ),
        }),
        createRoute({
          path: WebRoutes.orderOtherService,
          component: (
            <OrderAccess
              component={<OrderGenericServiceScreen serviceType={ServiceType.Other} />}
            />
          ),
        }),
        createRoute({
          path: WebRoutes.quoteWeeding,
          component: (
            <OrderAccess
              component={<GenericQuoteServiceScreen serviceType={ServiceType.Weeding} />}
            />
          ),
        }),
        createRoute({
          path: WebRoutes.orderPressureWashing,
          component: (
            <OrderAccess
              component={<GenericQuoteServiceScreen serviceType={ServiceType.PressureWashing} />}
            />
          ),
        }),
        createRoute({
          path: WebRoutes.orderTreeTrimming,
          component: (
            <OrderAccess
              component={<GenericQuoteServiceScreen serviceType={ServiceType.TreeTrimming} />}
            />
          ),
        }),
        createRoute({
          path: WebRoutes.orderLawnSeeding,
          component: (
            <OrderAccess
              component={<GenericQuoteServiceScreen serviceType={ServiceType.LawnSeeding} />}
            />
          ),
        }),
        createRoute({
          path: WebRoutes.orderSodding,
          component: (
            <OrderAccess
              component={<GenericQuoteServiceScreen serviceType={ServiceType.Sodding} />}
            />
          ),
        }),
        createRoute({
          path: WebRoutes.orderShrubOrTreePlanting,
          component: (
            <OrderAccess
              component={
                <GenericQuoteServiceScreen serviceType={ServiceType.ShrubOrTreePlanting} />
              }
            />
          ),
        }),
        createRoute({
          path: WebRoutes.orderGutterCleaning,
          component: <OrderAccess component={<OrderGutterCleaningScreen />} />,
        }),
      ],
    },
    {
      path: WebRoutes.browseRedirect,
      element: <AuthorizedTemplate currentTab={HeaderTabs.browse} user={user} />,
      children: [
        createRoute({
          index: true,
          component: <BrowseRedirectScreen />,
        }),
      ],
    },
    {
      path: WebRoutes.order,
      element: <AuthorizedTemplate currentTab={HeaderTabs.browse} user={user} />,
      children: [
        createRoute({
          index: true,
          component: <BrowseScreen />,
        }),
        createRoute({
          path: WebRoutes.orderAeration,
          component: <OrderAccess component={<OrderAerationScreen />} />,
        }),
        createRoute({
          path: WebRoutes.orderLawnTreatment,
          title: t('ltrs.aboutLawnTreatment.pageTitle'),
          component: <OrderAccess component={<OrderLawnTreatmentScreen />} />,
        }),
        createRoute({
          path: WebRoutes.confirmLawnTreatment,
          title: t('ltrs.scheduleLtrs.pageTitle'),
          component: <OrderAccess component={<ConfirmLawnTreatmentScreen />} />,
        }),
      ],
    },
    {
      path: WebRoutes.orderLawnMowing,
      element: <AuthorizedTemplate currentTab={HeaderTabs.browse} user={user} />,
      children: [
        createRoute({
          index: true,
          component: <OrderAccess component={<OrderLawnMowingScreen />} />,
        }),
        createRoute({
          path: WebRoutes.reactivationPolicies,
          component: <OnlyProgrammaticAccess component={<ReactivationPoliciesScreen />} />,
        }),
      ],
    },
    {
      path: WebRoutes.reviewScreen,
      element: <AuthorizedTemplate currentTab={HeaderTabs.browse} user={user} />,
      children: [
        createRoute({
          index: true,
          component: <BrowseScreen />,
        }),
      ],
    },
  ];
}
