import { useMemo } from 'react';
import { SCHEDULE_CYCLE_MONTHLY } from '@lawnstarter/customer-modules/constants';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { useOrderServiceViewedTracking } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_orderLtrsService,
  properties_currentPropertySelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { LtrsServices } from '@src/components/layouts';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import type { AerationFormData } from '@src/components/organisms/forms';

export function OrderMosquitoPreventionScreen() {
  const { navigate } = useRouteNavigation();
  const dispatch = useDispatch();
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const property = useSelector(properties_currentPropertySelector);

  const apiTracking = useApiTracking()!;
  useOrderServiceViewedTracking(apiTracking, ServiceType.MosquitoPrevention);

  const { price, service } = useMemo(() => {
    const { options, service } = property?.priced?.services?.mosquito ?? {
      options: [{}],
      service: {},
    };
    const [price] = options;

    return {
      price,
      service,
    };
  }, [property?.priced?.services?.mosquito]);

  const submit = async (data: AerationFormData) => {
    const dataToCallAction = {
      price: price?.price,
      cycle: SCHEDULE_CYCLE_MONTHLY,
      property_id: property.id,
      service_id: service?.service_id,
      starts_at: data.starts_at,
    };

    const {
      success = false,
      error,
      scheduleId,
    } = await dispatch(orderServices_orderLtrsService(dataToCallAction));

    if (success) {
      navigate(WebRoutes.serviceDetail, {
        params: { propertyId: property.id, scheduleId },
      });
    } else {
      modal.show({
        icon: 'block-helper',
        action: t('somethingWentWrong'),
        messages: [error?.error?.message ?? t('apiGenericError')],
        buttonText: t('tryAgain'),
      });
    }
  };

  return (
    <LtrsServices
      service={{
        name: t('ltrs.services.mosquito.information.header'),
        description: t('ltrs.services.mosquito.information.content'),
        bulletPoints: [
          t('ltrs.services.mosquito.information.bulletPoints.findPlaces'),
          t('ltrs.services.mosquito.information.bulletPoints.sprayAreas'),
          t('ltrs.services.mosquito.information.bulletPoints.returnMontly'),
        ],
        notice: {
          firstLine: t('ltrs.services.mosquito.notice.serviceWindow'),
          secondLine: t('ltrs.services.mosquito.notice.chargedAfterServiceCompletion'),
        },
        price: {
          title: t('ltrs.services.mosquito.title'),
          recurrencyMode: t('ltrs.services.mosquito.price.recurrency'),
          unitCharge: t('ltrs.services.mosquito.price.unit'),
        },
        type: ServiceType.MosquitoPrevention,
      }}
      submit={submit}
    />
  );
}
