import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useMemo } from 'react';
import Slider from 'react-slick';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { AdBanner } from '@lawnstarter/ls-react-common/molecules';

import { useRouteNavigation, useWindowSize } from '@src/hooks';

import { StyledCardContainer, StyledCarouselContainer, StyledInnerCardContainer } from './styles';

import type { CarouselProps } from './types';

export function RecommendationCarousel({
  items,
  itemBeforePress,
  dots,
  infinate,
  slidesToScroll,
  slidesToShow,
}: CarouselProps) {
  const theme = useAppTheme();
  const window = useWindowSize();
  const { navigate } = useRouteNavigation();

  const numberToShow = (width: number): number => {
    return width < 725 ? 1 : width <= 1023 ? 2 : 3;
  };

  const action = ({ routeName, params }: { routeName: WebRoutes; params: any }) => {
    navigate(routeName, { params });
  };

  const renderItems = useMemo(() => {
    return items.map((item) => (
      <StyledCardContainer key={item.title}>
        <StyledInnerCardContainer
          style={{
            background: theme.colors.white.primary,
            boxShadow: '0px 1px 2px 2px lightGray',
          }}
        >
          <AdBanner
            title={item.title}
            image={item.imageUrl}
            ctaText={item.actionText}
            beforePress={itemBeforePress}
            onPress={() => action(item.action)}
            description={item.text}
          />
        </StyledInnerCardContainer>
      </StyledCardContainer>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemBeforePress, items, theme.colors.white.primary]);

  function SimpleSlider() {
    var settings = {
      dots: dots ?? true,
      infinite: infinate ?? true,
      speed: 500,
      slidesToShow: slidesToShow ?? numberToShow(window.width),
      slidesToScroll: slidesToScroll ?? numberToShow(window.width),
      arrows: false,
    };

    // carousel does not like only having one item in the array breaks UI
    if (items.length === 1) {
      return <div className="w-full flex justify-center">{renderItems}</div>;
    }

    return (
      <StyledCarouselContainer>
        <Slider {...settings}>{renderItems}</Slider>
      </StyledCarouselContainer>
    );
  }

  return <SimpleSlider />;
}
