import { useMemo } from 'react';
import { Image, ImageSourcePropType, Linking, Pressable, View } from 'react-native';
import { useParams } from 'react-router-dom';
import LawnTreatmentMonth1Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_1.png';
import LawnTreatmentMonth5Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_5.png';
import LawnTreatmentMonth11Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_11.png';
import { LTRS_FAQ_ITEMS } from '@lawnstarter/customer-modules/constants';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getBrand } from '@lawnstarter/customer-modules/helpers';
import { useOrderServiceViewedTracking } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking } from '@lawnstarter/ls-react-common/hooks';
import { Question } from '@lawnstarter/ls-react-common/molecules';

import { AboutLawnTreatment, HelperBottomSheet } from '@src/components';
import { PlanDetailsTable } from '@src/components/molecules/Table/PlanDetailsTable.tsx';
import { useRouteNavigation, useSelector } from '@src/hooks';

import { MobileOrderLawnTreatmentTemplate } from './MobileOrderLawnTreatmentTemplate';
import { DesktopOnlyWrapper, MobileOnlyWrapper } from './MobileStyles';
import {
  OrderLawnTreatmentContainer,
  StyledOrderLawnTreatmentContainer,
  StyledOrderLawnTreatmentInfoContainer,
  styles,
} from './styles';

export function OrderLawnTreatmentScreen() {
  const { propertyId } = useParams();
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const { navigate } = useRouteNavigation();

  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  function onPhonePress(phoneNumber: string) {
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
    Linking.openURL(`tel:+${formattedPhoneNumber}`);
  }

  const lawnTreatmentInfo = useSelector(
    ({ properties }) =>
      properties.propertiesById[propertyId as string]?.priced?.services?.fertilization,
  );

  const apiTracking = useApiTracking()!;
  useOrderServiceViewedTracking(apiTracking, ServiceType.LawnTreatment);

  const renderOrderLawnTreatmentContainer = () => {
    return (
      <StyledOrderLawnTreatmentInfoContainer>
        <Pressable
          accessibilityLabel={t('goBack')}
          onPress={() => navigate(WebRoutes.back)}
          style={style.goBackIconContainer}
        >
          <Icon name="arrow-left" size={theme.sizing.s6} style={style.goBackIcon} />
        </Pressable>

        <OrderLawnTreatmentContainer style={style.orderLawnTreatmentContainer}>
          <Text variant="headlineLarge">{t('ltrs.orderLawnTreatment.title')}</Text>

          <br />
          <View style={{ marginBottom: 16 }} />

          <Text variant="labelLarge" style={style.orderLawnTreatmentSubtitle}>
            {t('ltrs.orderLawnTreatment.subtitle')}
          </Text>

          <View style={{ marginBottom: 12 }} />

          <Text variant="labelLarge" style={style.orderLawnTreatmentDescription}>
            {t('ltrs.orderLawnTreatment.description')}
          </Text>

          <View style={style.orderLawnTreatmentItems}>
            <Icon name="card-search-outline" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('ltrs.orderLawnTreatment.items.certifiedPro')}
            </Text>
          </View>

          <View style={style.orderLawnTreatmentItems}>
            <Icon name="star-circle-outline" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('ltrs.orderLawnTreatment.items.guaranteedFreeTouchUps')}
            </Text>
          </View>

          <View style={style.orderLawnTreatmentItems}>
            <Icon name="bone" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('ltrs.orderLawnTreatment.items.safeForPetsAndFamilies')}
            </Text>
          </View>

          <View>
            <Text variant="bodyLarge" style={style.planDetailsTitle} allowFontScaling={false}>
              {t('ltrs.orderLawnTreatment.planDetails')}
            </Text>
          </View>
          <PlanDetailsTable />

          <View style={style.imagesContainer}>
            <View style={style.smallImagesContainer}>
              <Image
                source={LawnTreatmentMonth1Photo as ImageSourcePropType}
                style={style.smallImage}
              />
              <Text variant="labelMedium" style={style.smallImageSubtitle}>
                {t('ltrs.orderLawnTreatment.imageSubtitles.monthOne')}
              </Text>
            </View>
            <View style={style.smallImagesContainer}>
              <Image
                source={LawnTreatmentMonth5Photo as ImageSourcePropType}
                style={style.smallImage}
              />
              <Text variant="labelMedium" style={style.smallImageSubtitle}>
                {t('ltrs.orderLawnTreatment.imageSubtitles.monthFive')}
              </Text>
            </View>
          </View>

          <View style={style.largeImagesContainer}>
            <Image
              source={LawnTreatmentMonth11Photo as ImageSourcePropType}
              style={style.largeImage}
            />
            <Text variant="labelMedium" style={style.largeImageSubtitle}>
              {t('ltrs.orderLawnTreatment.imageSubtitles.monthEleven')}
            </Text>
            <Text variant="bodySmall" style={style.footer}>
              {t('ltrs.orderLawnTreatment.footer')}
            </Text>
          </View>
        </OrderLawnTreatmentContainer>
      </StyledOrderLawnTreatmentInfoContainer>
    );
  };

  const renderLawnTreatmentComponents = () => {
    return (
      <AboutLawnTreatment
        onPress={() => {
          navigate(WebRoutes.confirmLawnTreatment, {
            params: {
              propertyId: propertyId as string,
            },
          });
        }}
        price={lawnTreatmentInfo?.options[0].price ?? ''}
        isLoading={isUpdating}
        shouldOrder={false}
      />
    );
  };

  const renderFAQContent = () =>
    LTRS_FAQ_ITEMS.map((item) => {
      if (item.title === t('ltrs.faq.howToGetMoreInformation.title')) {
        const phoneNumber = t(`ltrs.faq.howToGetMoreInformation.phone.${getBrand().slug}`);
        const faqContent = t('ltrs.faq.howToGetMoreInformation.content', { phoneNumber });

        const [beforePhoneNumber, afterPhoneNumber] = faqContent.split(phoneNumber);

        return (
          <Question
            key={item.title}
            question={item.title}
            trackID="about_lawn_treatment-ltrs_faq_items"
          >
            <Text
              style={{
                width: 320,
                marginTop: theme.spacing.s3,
                marginLeft: theme.spacing.s1,
              }}
            >
              {beforePhoneNumber}
              <Text
                style={{ color: theme.colors.blue.primary }}
                onPress={() => onPhonePress(phoneNumber)}
              >
                {phoneNumber}
              </Text>{' '}
              {afterPhoneNumber.trim()}
            </Text>
          </Question>
        );
      }

      return (
        <Question
          key={item.title}
          question={item.title}
          trackID="about_lawn_treatment-ltrs_faq_items"
        >
          <Text
            style={{
              width: 320,
              marginTop: theme.spacing.s3,
              marginLeft: theme.spacing.s1,
            }}
          >
            {item.content}
          </Text>
        </Question>
      );
    });

  const renderSheetTitle = () => (
    <Text
      variant="bodyMedium"
      style={{
        fontSize: theme.fonts.headlineSmall.fontSize,
        fontFamily: theme.fontVariant.primary.w600,
        fontWeight: '600',
        marginLeft: theme.spacing.s1,
      }}
    >
      {t('ltrs.faq.bottomSheetTitle')}
    </Text>
  );

  return (
    <>
      <MobileOnlyWrapper>
        <MobileOrderLawnTreatmentTemplate />
        <HelperBottomSheet content={renderFAQContent()} title={renderSheetTitle()} />
      </MobileOnlyWrapper>
      <DesktopOnlyWrapper>
        <StyledOrderLawnTreatmentContainer>
          {renderLawnTreatmentComponents()}
          {renderOrderLawnTreatmentContainer()}
        </StyledOrderLawnTreatmentContainer>
      </DesktopOnlyWrapper>
    </>
  );
}
