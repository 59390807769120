import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { properties_currentPropertyIdSelector } from '@lawnstarter/customer-modules/stores/modules';
import { ModalContext } from '@lawnstarter/ls-react-common/contexts';
import { useModal } from '@lawnstarter/ls-react-common/hooks';

import { setServiceReviewShown, wasServiceReviewShown } from '@src/helpers';
import { useRouteNavigation } from '@src/hooks';
import { ServiceReviewModal } from '@src/modals';

import { useSelector } from './useSelector';

import type { SkippableHookProps } from '@src/types';

export function useAskServiceReviewByRoute({ skip = false }: SkippableHookProps = {}) {
  const { navigate } = useRouteNavigation();
  const modalContext = useContext(ModalContext);
  const reviewModal = useModal(ServiceReviewModal);
  const isServiceReviewRoute = useMatch(WebRoutes.reviewScreen);
  const { propertyId, scheduleId, scheduleEventId } = useParams();
  const [hasAskedReview, setAskedReview] = useState(false);
  const [hasFinished, setFinished] = useState(false);

  const currentPropertyId = useSelector(properties_currentPropertyIdSelector);

  const isFetching = useSelector(({ schedules, properties }) => {
    return schedules.loadingStatus.isLoading || properties.loadingStatus.isLoading;
  });

  const schedule = useSelector(({ schedules }) => {
    return schedules.schedulesById[scheduleId ?? ''];
  });

  const completedEvent = useMemo(() => {
    if (!schedule || !currentPropertyId || Number(propertyId) !== Number(currentPropertyId)) {
      return null;
    }

    const scheduleEvents = schedule?.recent_schedule_events ?? schedule?.scheduleevents ?? [];
    const foundEvent = scheduleEvents.find(({ id }) => id === Number(scheduleEventId));

    return foundEvent || null;
  }, [currentPropertyId, propertyId, schedule, scheduleEventId]);

  const onDismiss = useCallback(() => {
    navigate(WebRoutes.browse, {
      params: { propertyId: propertyId! },
      options: { replace: true },
    });
  }, [navigate, propertyId]);

  useEffect(() => {
    const shouldSkip =
      skip || // Explicitly received to skip the hook.
      isFetching || // We are waiting Redux data to load.
      hasFinished; // This hook finished processing.

    if (shouldSkip) {
      return;
    }

    const cantReview =
      modalContext.visible || // A modal is already visible.
      !isServiceReviewRoute || // Shouldn't run outside the review deep link route.
      !completedEvent || // No completed event to ask for review.
      !completedEvent.is_reviewable || // Service is not reviewable.
      Boolean(completedEvent.reviews?.length) || // Service already has reviews.
      wasServiceReviewShown(completedEvent.id); // User has already been asked for review.

    if (cantReview) {
      setFinished(true);
      return;
    }

    setServiceReviewShown(completedEvent.id);
    setAskedReview(true);
    setFinished(true);

    const { schedule } = completedEvent;

    reviewModal.show({
      animationType: 'fade',
      backdropCloseable: false,
      closeable: true,
      scheduleEventId: completedEvent.id.toString(),
      propertyId: schedule?.property_id?.toString(),
      scheduleId: schedule?.schedule_id?.toString(),
      onDismiss,
    });
  }, [
    completedEvent,
    hasFinished,
    isFetching,
    isServiceReviewRoute,
    modalContext.visible,
    onDismiss,
    reviewModal,
    skip,
  ]);

  return {
    hasAskedReview,
    isLoading: !hasFinished,
  };
}
