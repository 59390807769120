import { useMemo } from 'react';
import { Pressable } from 'react-native';
import { useParams } from 'react-router-dom';
import {
  useChangeMyProController,
  useChangeProController,
} from '@lawnstarter/customer-modules/controllers';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { useFeatureFlag } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeType } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import {
  ChangeMyProForm,
  ChangeProForm,
  ModalTemplate,
  Notice,
} from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useAppDownloadModal, useRouteNavigation } from '@src/hooks';

import {
  style,
  StyledButtons,
  StyledButtonWrapper,
  StyledFormWrapper,
  StyledWrapper,
} from './styles';

export const ChangeMyProviderScreen = () => {
  const isFlagImproveCancellationChangeProImprovements = useFeatureFlag(
    FeatureFlags.IMPROVE_CANCELLATION_FLOW_CHANGE_PRO_IMPROVEMENTS,
  );

  return isFlagImproveCancellationChangeProImprovements ? (
    <ChangeMyProviderScreenNew />
  ) : (
    <ChangeMyProviderScreenOld />
  );
};

const ChangeMyProviderScreenOld = () => {
  const theme = useAppTheme();
  const styles = useMemo(() => style(theme), [theme]);

  const { navigate } = useRouteNavigation();
  const { showAppDownloadModal } = useAppDownloadModal();

  const actionModal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const binaryModal = useModal(ModalTemplate.BINARY_CHOICE);
  const stepsModal = useModal(ModalTemplate.STEPS);

  const replaceWithServiceRoute = () =>
    navigate(WebRoutes.services, { options: { replace: true } });

  const { fields, control, isUpdating, whatToExpectNotice, handleSubmit, currentSelectedReason } =
    useChangeMyProController({
      modals: {
        actionModalInstance: actionModal,
        binaryChoiceModalInstance: binaryModal,
        stepsModalInstance: stepsModal,
      },
      onDismissIneligibleProsModal: replaceWithServiceRoute,
      onDismissSuccessModal: replaceWithServiceRoute,
      onSelectLetProFix: () => {
        // Give enough time for the previous modal to finish its animation
        setTimeout(showAppDownloadModal, 150);
      },
    });

  return (
    <div>
      <DetailsHeader showBackButton={true} />

      <div>
        <ScrollView>
          <StyledFormWrapper>
            <Notice type={NoticeType.Hint}>
              <div className="flex flex-col gap-2 pb-3">
                <div className="text-center ">
                  <Text variant="titleMedium" style={styles.noticeTitle}>
                    {whatToExpectNotice.title}
                  </Text>
                </div>
                <Text>{whatToExpectNotice.message}</Text>
                <Pressable onPress={whatToExpectNotice.onLearnMorePress}>
                  <Text style={styles.noticeButton}>{whatToExpectNotice.button}</Text>
                </Pressable>
              </div>
            </Notice>

            <ChangeMyProForm
              fields={fields}
              control={control}
              isUpdating={isUpdating}
              origin="change_my_provider_screen"
              withMoreReasonDetails={currentSelectedReason === 'customer.other'}
            />
          </StyledFormWrapper>
        </ScrollView>
      </div>

      <StyledButtons>
        <Button
          mode="contained-tonal"
          style={{ flex: 1 }}
          trackID="change_my_provider_screen-cancel"
          onPress={() => navigate(WebRoutes.back)}
        >
          {t('cancel')}
        </Button>

        <Button
          mode="contained"
          style={{ flex: 1 }}
          trackID="change_my_provider_screen-confirm"
          onPress={handleSubmit}
        >
          {t('submitRequest')}
        </Button>
      </StyledButtons>
    </div>
  );
};

const ChangeMyProviderScreenNew = () => {
  const { navigate } = useRouteNavigation();
  const { showAppDownloadModal } = useAppDownloadModal();

  const confirmationModal = useModal(ModalTemplate.CONFIRMATION);

  const replaceWithServiceRoute = () =>
    navigate(WebRoutes.services, { options: { replace: true } });

  const { scheduleId } = useParams();

  const {
    fields,
    control,
    isUpdating,
    handleSubmit,
    descriptionLabel,
    descriptionText,
    noticeTexts,
  } = useChangeProController({
    confirmationModal,
    onDismissIneligibleProsModal: replaceWithServiceRoute,
    onDismissSuccessModal: replaceWithServiceRoute,
    onSelectLetProFix: () => {
      // Give enough time for the previous modal to finish its animation
      setTimeout(showAppDownloadModal, 150);
    },
    scheduleId: Number(scheduleId),
  });

  return (
    <>
      <DetailsHeader showBackButton={true} />

      <StyledWrapper>
        <ScrollView>
          <ChangeProForm
            control={control}
            descriptionLabel={descriptionLabel}
            descriptionText={descriptionText}
            fields={fields}
            isUpdating={isUpdating}
            noticeTexts={noticeTexts}
            origin="change_my_provider_screen"
          />
        </ScrollView>

        <StyledButtonWrapper>
          <Button
            mode="contained"
            style={{ flex: 1 }}
            trackID="change_my_provider_screen-confirm"
            onPress={handleSubmit}
          >
            {t('submitRequest')}
          </Button>
        </StyledButtonWrapper>
      </StyledWrapper>
    </>
  );
};
